import { Box, } from "@mui/material";
import { ExpandableList } from "./common";
import TocIcon from '@mui/icons-material/Toc';

export default function TableOfContent({ content, activeTrainingId, setActiveTrainingId,
    completedTrainings
 }) {
    return (
        <Box>
           <ExpandableList
           isOpen={true}
           title="Table of Content" icon={<TocIcon />} content={content} 
           completedTrainings={completedTrainings}
           activeTrainingId={activeTrainingId} setActiveTrainingId={setActiveTrainingId} />
        </Box>
    );
}
