import { Box, Card, CircularProgress, Grid, IconButton, Stack } from "@mui/material";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useEffect, useState } from "react";
import parse from 'html-react-parser';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { styled } from "@mui/material";

import TrainingQuiz from "./TrainingQuiz";
import instance from "../../../../auth/utils/useJwt";
import './style.css'

// with theme
const ContentContainer = styled(Box)(({ theme }) => {
    return {
        height: "calc(100vh - 97px)",
        overflowY: "auto",

    }
})


export default function SingleTraining({
    open, setOpen, activeTrainingId, folder, folderMeta, mainLoading, nextPage, prevPage, setTrainingCompleted
}) {
    const [training, setTraining] = useState(null)

    const [loading, setLoading] = useState(true)
    const [startTime, setStartTime] = useState(null)
    const fetchTraining = async () => {
        setLoading(true)
        instance.get(`/training?folder=${folderMeta?.slug}&slug=${activeTrainingId}`).then(res => {
            setLoading(false)

            //assign each h2 heading an id
            const domParser = new DOMParser();
            const doc = domParser.parseFromString(res.data.content, 'text/html');
            const headings = doc.querySelectorAll('h2');
            headings.forEach((heading, index) => {
                heading.id = `heading-ehr-${index}`
            })

            res.data.content = doc.body.innerHTML

            setTraining(res.data)
            setStartTime(new Date())
            updateProgress(res.data.id)
            setTrainingCompleted(res.data)
        }).catch(err => {
            console.log(err)
            setLoading(false)
        })
    }

    const updateProgress = (training_id) => {
        if (!training_id) return
        const endTime = new Date()
        // get in seconds
        const time = (endTime - startTime) / 1000
        console.log(time)
        console.log(endTime)

        instance.post("/training/progress", {
            training_id: training_id,
            time: 1
        }).then(res => {
        }).catch(err => {
        })
    }

    useEffect(() => {
        fetchTraining()
    }, [folder, activeTrainingId])



    return (
        <Box sx={{
            width: open ? "calc(100% - 300px)" : "100%",
            // Transition added for smooth animation
            transition: "width 0.5s",
            height: "100vh",
        }}>
            {!open &&
                <Box>
                    <IconButton onClick={() => setOpen(true)}>
                        <ArrowForwardIosIcon />
                    </IconButton>
                </Box>
            }

            {
                loading || mainLoading ? <Box sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "80vh"
                }}>
                    <CircularProgress />
                </Box>

                    :
                    <Box className="single-training">
                        <Box sx={{ height: '45px' }}>
                            {training &&
                                <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}
                                    sx={{ borderBottom: '1px solid #ccc', px: 2, height: '100%' }}>
                                    <Box sx={{ fontSize: "1.5rem", fontWeight: "bold" }}>
                                        {training.title}
                                    </Box>
                                    <Stack display={"flex"} alignItems={"center"} direction="row" gap={0.5}>

                                        <AccessTimeIcon /> {training.reading_minutes} minutes

                                    </Stack>
                                </Stack>
                            }
                        </Box>
                        <ContentContainer>
                            {training && <>
                                {training.title !== "test training 5" ?
                                    <Box sx={{ px: 2 }}>
                                        {training.content && parse(training.content)}
                                    </Box>
                                    :
                                    <Grid container spacing={2} sx={{ p: 2 }}>
                                        <Grid item xs={12} md={6}>
                                                <Card sx={{ py: 0.5, px: 2 }}>
                                                   { training.content && parse(training.content)}
                                                </Card>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                                <TrainingQuiz training={training} />
                                        </Grid>
                                    </Grid>

                                }
                            </>}

                        </ContentContainer>
                        <Box sx={{
                            height: '50px',
                        }}>
                            <Stack direction={"row"} justifyContent={"space-between"} sx={{
                                px: 2, borderTop: '1px solid #ccc',
                                height: '100%',
                                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                            }}>
                                <IconButton onClick={() => prevPage()}>
                                    <ArrowBackIosIcon />
                                </IconButton>
                                <IconButton onClick={() => {
                                    // updateProgress()
                                    nextPage()
                                }}>
                                    <ArrowForwardIosIcon />
                                </IconButton>
                            </Stack>
                        </Box>
                    </Box>
            }



        </Box>
    )
}