import React, { useEffect, useState } from "react";
import AppLayout from "../../../../components/layout/AppLayout";
import Box from '@mui/material/Box'
import BContainer from "../../../../components/BContainer";
import BreadCrumbs from "../../../../components/BreadCrumbs";
import instance from "../../../../auth/utils/useJwt";
import { Button, IconButton } from "@mui/material";
import GeneralDialog from "../../../../components/Dialogs/CustomDialog";
import VisibilityIcon from '@mui/icons-material/Visibility';
import parse from 'html-react-parser';

export default function PreviewUnit({unit}) {
    const [open, setOpen] = useState(false)
    return (
        <Box>
            <IconButton onClick={() => setOpen(true)}>
                <VisibilityIcon />
            </IconButton>

            <GeneralDialog maxWidth="lg" open={open} handleClose={() => setOpen(false)} title={unit.title + " Preview"}>
                <Box>
                    <Box>
                        {unit.content && parse(unit.content)}
                    </Box>
                </Box>
            </GeneralDialog>
        </Box>
    )
}