import { Radio, Box } from "@mui/material";

export default function QuizOptions({ options, onChange, selected }) {
    // [{ value: "Lagos"}]
    return (
        <Box>
            {options.map((option, index) => (
                <Box key={index}>
                    <Radio size="small" sx={{ pl: 0, py: 0.5 }}
                        checked={selected === option.value}
                        onChange={() => onChange(option.value)}
                        value={option.value}
                        name="radio-buttons-group"
                    />
                    {option.value}
                </Box>
            ))}
        </Box>
    )
}