import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import {
    Box, Button, Card, CardContent, Grid, LinearProgress, Stack, Typography
} from '@mui/material'
import { GridCheckCircleIcon } from "@mui/x-data-grid";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CircularProgressFeedback from "../../../components/General/CircularProgressFeedback";
import instance from '../../../auth/utils/useJwt'
import AppLayout from '../../../components/layout/AppLayout'
import BContainer from '../../../components/BContainer'
import BreadCrumbs from '../../../components/BreadCrumbs'


export default function TrainingUnitsUser() {
    const [loading, setLoading] = useState(true)
    const [trainingUnits, setTrainingUnits] = useState([])
    const [tableOfContent, setTableOfContent] = useState([])
    const [activeTrainingId, setActiveTrainingId] = useState(null)
    const [completedTrainings, setCompletedTrainings] = useState([])
    const [courseMeta, setCourseMeta] = useState({})
    const { slug } = useParams()

    const fetchTrainings = async () => {
        setLoading(true)
        instance.get(`/training/pages?slug=${slug}`).then(res => {
            setLoading(false)
            console.log(res.data)
            setTrainingUnits(res.data?.trainings)
            setCourseMeta(res.data?.folder)

            // Make toc
            const headings = res.data?.trainings.map(item => ({
                title: item.title,
                id: item.slug
            }))
            setTableOfContent(headings)
            setActiveTrainingId(headings[0].id)

            // store ids of completed trainings
            const completed = res.data?.trainings.filter(item => item.completed).map(item => item.slug)
            setCompletedTrainings(completed)

        }).catch(err => {
            console.log(err)
            setLoading(false)
        })
    }

    const get_button_text = (training) => {
        if (training.completed) {
            return "Completed"
        } else {
            return "Start"
        }
    }

    const openWindow = (url) => {
        const width = window.innerWidth / 1.4;
        const height = window.innerHeight / 1.2;
        const left = (window.innerWidth / 2) - (width / 2);
        const top = (window.innerHeight / 2) - (height / 2);

        const newWindow = window.open(url, "", `width=${width}, height=${height}, top=${top}, left=${left}`);

        const checkWindowClosed = setInterval(() => {
            if (newWindow.closed) {
                clearInterval(checkWindowClosed);
                console.log("Window was closed");
                fetchTrainings()
            }
        }, 1000); // Check every second
    }




    useEffect(() => {
        fetchTrainings()
    }, [])

    return (
        <AppLayout title="Training Units" px={0} apppadding={"0px"}>
            <BContainer>
                <BreadCrumbs data={[
                    { text: "Training", url: "/training" },
                    { text: "Training Units", url: "/training/units" }
                ]} />
            </BContainer>
            <Box sx={{ px: 3, py: 1 }}>
                <Grid container spacing={2}>
                    <Grid item md={8}>
                        {/* <Card> */}
                        {loading && <LinearProgress />}
                        <Box>

                            {
                                trainingUnits.map((training, index) => (
                                    <Card key={index} sx={{ mb: 2 }}>
                                        <Stack direction="row" gap={1} alignItems="center">
                                            <Box sx={{ px: 3, py: 2 }}>
                                                <Stack direction="row" spacing={1} alignItems="center">
                                                    <Typography variant="h5">{training.title}</Typography>
                                                    {training.completed === training.trainings?.length && <GridCheckCircleIcon style={{ color: "#06F", fontSize: "1.3rem" }} />}
                                                </Stack>
                                                <Typography variant="subtitle2" sx={{
                                                    color: "#666", fontSize: "0.8rem"
                                                }}>
                                                    {`Total Estimated Time: ${training.reading_minutes} minutes`}
                                                </Typography>
                                                <Stack direction={"row"} gap={2} sx={{ mt: 2 }}>
                                                    <Box>
                                                        <Button variant="contained"
                                                            endIcon={<ArrowForwardIcon />}
                                                            sx={{
                                                                textTransform: 'capitalize'
                                                            }} size="small"
                                                            onClick={() => openWindow(`/training/${courseMeta.slug}`)}
                                                        // component={Link} to={`/training/${training.folder.slug}`}
                                                        >
                                                            {/* {training.completed === 0 ? "Start" : "Resume"}
                                                             */}
                                                            {get_button_text(training)}
                                                        </Button>
                                                    </Box>
                                                    
                                                </Stack>
                                            </Box>
                                        </Stack>

                                    </Card>
                                ))
                            }



                        </Box>

                        {/* </Card> */}
                    </Grid>
                    <Grid item md={4}>
                        <Card>
                            <Box sx={{
                                borderBottom: '1px solid #ddd', p: 2, fontSize: "1.2rem", fontWeight: 500
                            }}>Progress</Box>
                            <CardContent>
                                <Typography variant="subtitle2">You have completed {
                                    completedTrainings.length
                                } out of {
                                    trainingUnits.length
                                    } trainings</Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Box>
        </AppLayout>
    )


}