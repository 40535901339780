import { Box, Button, Card, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import QuizOptions from "./QuizOptions";

export default function TrainingQuiz() {
    const [quiz, setQuiz] = useState([
        {
            id: 1,
            question: "What is the capital of Nigeria?",
            options: [
                { value: "Lagos", correct: false },
                { value: "Abuja", correct: true },
                { value: "Kano", correct: false },
                { value: "Ibadan", correct: false },
            ]
        },
        {
            id: 2,
            question: "What is the capital of Ghana?",
            options: [
                { value: "Accra", correct: true },
                { value: "Kumasi", correct: false },
                { value: "Tamale", correct: false },
                { value: "Tema", correct: false },
            ]
        },
        {
            id: 3,
            question: "What is the capital of Kenya?",
            options: [
                { value: "Nairobi", correct: true },
                { value: "Mombasa", correct: false },
                { value: "Kisumu", correct: false },
                { value: "Nakuru", correct: false },
            ]
        },
        {
            id: 4,
            question: "What is the capital of South Africa?",
            options: [
                { value: "Johannesburg", correct: false },
                { value: "Pretoria", correct: false },
                { value: "Cape Town", correct: false },
                { value: "Bloemfontein", correct: true },
            ]
        },
    ])
    const [currentQuestion, setCurrentQuestion] = useState(null)
    const [currentQuestionNumber, setCurrentQuestionNumber] = useState(1)
    const [selected, setSelected] = useState({})

    const handleOptionChange = (value, qid) => {
        setSelected({ ...selected, [qid]: value })
    }

    useEffect(() => {
        setCurrentQuestion(quiz[0])
    }, [quiz])

    const handleNext = () => {
        if (currentQuestionNumber < quiz.length) {
            setCurrentQuestion(quiz[currentQuestionNumber])
            setCurrentQuestionNumber(currentQuestionNumber + 1)
        }
    }

    const handlePrevious = () => {
        if (currentQuestionNumber > 1) {
            setCurrentQuestion(quiz[currentQuestionNumber - 2])
            setCurrentQuestionNumber(currentQuestionNumber - 1)
        }
    }


    return (
        <Box>
            {currentQuestion === null ? <Box>No questions available</Box> :
                <Card>
                    <Box sx={{ py: 2, px: 3 }}>
                        <Stack direction="row" gap={2}>
                            <Typography variant="h6">Q.{currentQuestionNumber}</Typography>
                            <Box>
                                <Typography variant="h6" sx={{ color: "#FC8525" }}>{currentQuestion.question}</Typography>


                                <QuizOptions
                                    options={currentQuestion.options}
                                    onChange={(value) => handleOptionChange(value, currentQuestion.id)}
                                    selected={selected[currentQuestion.id]} />
                            </Box>
                        </Stack>
                        <Stack direction="row" spacing={2} justifyContent="space-between" sx={{ py: 1 }}>
                            <Box>
                                {currentQuestionNumber} of {quiz.length}
                            </Box>
                            <Stack direction="row" spacing={2}>

                                <Box>
                                    <Button size="small" variant="outlined"
                                        disabled={currentQuestionNumber === 1}
                                        onClick={handlePrevious}
                                    >Previous</Button>
                                </Box>
                                <Box>
                                    <Button size="small" variant="contained" color="primary"
                                        disabled={currentQuestionNumber === quiz.length}
                                        onClick={handleNext}
                                    >Next</Button>
                                </Box>
                            </Stack>
                        </Stack>
                    </Box>
                </Card>
            }


        </Box>
    )
}